import React from 'react';
import './ShowSurvey.css';
import { AxiosError } from 'axios';
import { SurveyPropsType, SurveyStateType } from './ShowSurveyType';
// import backgroundImage from '../../../components/img/background.jpg';

import {  RequestPOST, set_error_message } from '../../../Request';
import Alert from '../../../components/Alert/Alert';
import Loading from '../../../components/Loading/Loading';
import BtnPrimary from '../../../components/Buttons/BtnPrimary/BtnPrimary';
import { url_studentGate } from '../../../public';

class ShowSurvey extends React.Component<SurveyPropsType, SurveyStateType> {
  /////////////////////////////////////////////////////////
  state: SurveyStateType = {
    select_material: this.props.surveyData.materials.length ? this.props.surveyData.materials[0].pk : null,
    answers: {},
    alert: [],
    loadingSave: false,
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////
  checkAnswers = () => {
    if (Object.keys(this.props.surveyData.materials).length !== Object.keys(this.state.answers).length) {
      this.setState({
        alert: ['يجب ملئ استبيان جميع المواد'],
      });
      return false;
    }
    let alert = [...this.state.alert];
    Object.entries(this.state.answers).forEach(([material_pk, answers]) => {
      if (Object.keys(answers).length !== this.props.surveyData.questions_survey.length) {
        alert = [
          ...this.state.alert,
          'يجب ملئ جميع اسئلة مادة ' + this.props.surveyData.materials.find((material) => material.pk === +material_pk)?.open_material__material_skill__material__name,
        ];
      }
    });
    this.setState({
      alert,
      loadingSave: alert.length === 0,
    });
    if (alert.length) return false;
    return true;
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  SetError = (error: AxiosError): void => {
    const alerts = set_error_message(error);
    this.setState({ alert: alerts, loadingSave: false });
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  sendSaveData = (): void => {
    if (this.state.loadingSave) {
      const bodyFormData = new FormData();
      bodyFormData.append('main_target', 'results');
      bodyFormData.append('answers', JSON.stringify(this.state.answers));
      RequestPOST(
        url_studentGate,
        bodyFormData,
        () => {
          this.props.setDoneSurvey();
        },
        this.SetError,
      );
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////
  componentDidMount() {
    this.sendSaveData();
  }
  //////////////////////////////////////////////////////////////////////////////
  componentDidUpdate() {
    this.sendSaveData();
  }
  /////////////////////////////////////////////////////////////////////////////////////////////////
  render(): JSX.Element | null {
    return (
      <div
        className="contentSurvey"
        // style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="head">
          <h1 className="title">استبيان المواد</h1>
          {this.props.surveyData.materials.length ? (
            <button onClick={this.checkAnswers} className="send">
              ارسال الاستبيان
            </button>
          ) : null}
        </div>
        {this.props.surveyData.materials.length ? (
          <div className="materials">
            {this.props.surveyData.materials.map((material) => {
              return (
                <p
                  className={'material' + (this.state.select_material === material.pk ? ' select' : '')}
                  onClick={() => {
                    this.setState({ select_material: material.pk });
                  }}
                  key={material.pk}
                >
                  {material.open_material__material_skill__material__name}
                </p>
              );
            })}
          </div>
        ) : null}
        {this.state.select_material ? (
          <div className="questions">
            {this.props.surveyData.questions_survey.map((question, index_question) => (
              <p className={'question'} key={question.pk}>
                {index_question + 1} - {question.name}
                <div className="buttons">
                  {['اوافق بشدة', 'اوافق', 'محايد', 'لا اوافق', 'لا اوافق بشدة'].map(
                    (value, index_chooses) =>
                      this.state.select_material && (
                        <button
                          className={this.state.answers[this.state.select_material]?.[question.pk] === index_chooses ? 'select' : undefined}
                          onClick={() => {
                            if (this.state.select_material)
                              this.setState({
                                answers: {
                                  ...this.state.answers,
                                  [this.state.select_material ?? '']: {
                                    ...this.state.answers[this.state.select_material],
                                    [question.pk]: index_chooses,
                                  },
                                },
                              });
                          }}
                          key={value}
                        >
                          {value}
                        </button>
                      ),
                  )}
                </div>
              </p>
            ))}
          </div>
        ) : null}

        {this.state.loadingSave ? <Loading /> : null}
        {this.state.alert.length > 0 && (
          <Alert
            title={'الملاحظات'}
            closeAlert={() => {
              this.setState({ alert: [] });
            }}
            notes={this.state.alert}
            btns={
              <BtnPrimary
                verbose_name="الغاء"
                OnClickBtn={() => {
                  this.setState({ alert: [] });
                }}
              />
            }
          />
        )}
      </div>
    );
  }
}

export default ShowSurvey;
