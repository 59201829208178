import React from 'react';
import '../../Common/Table.css';
import { AxiosError } from 'axios';
import { errorType, RequestGET, set_error_message } from '../../Request';
import { url_studentGate } from '../../public';
import { Tabs } from '../PageType';
import TitleTab from '../../components/TitleTab/TitleTab';
import Alert from '../../components/Alert/Alert';
import BtnPrimary from '../../components/Buttons/BtnPrimary/BtnPrimary';
import Loading from '../../components/Loading/Loading';
import { termName } from '../../Common/utilities';
import ShowResult, { resultsDataType } from './ShowResult/ShowResult';
import { AlertStatus, ResponseResult, ResponseType } from './ResultType';
import ShowSurvey from './ShowSurvey/ShowSurvey';
import { SurveyDataType } from './ShowSurvey/ShowSurveyType';

const ResponseIsResult = (response: ResponseType): response is ResponseResult => response.typeResponse === 'results';

const Result: React.FC = () => {
  const [resultsData, setResult] = React.useState<resultsDataType>({});
  const [surveyData, setSurveyData] = React.useState<SurveyDataType | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [alert, setAlert] = React.useState<errorType>([]);

  React.useEffect(() => {
    const handleError = (error: AxiosError): void => {
      setAlert(set_error_message(error));
      setLoading(false);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const handleDownload = (data: ResponseType): void => {
      if (ResponseIsResult(data)) {
        const results: typeof resultsData = {};
        const status_alert: errorType = data.resultsData.length > 0 ? [] : ['لا يوجد لديك نتيجة امتحانات مسجلة في ترمك الحالي'];
        data.resultsData.forEach((material_degree) => {
          const year = material_degree.student_material__student_term__term__academic_year__name;
          const term = material_degree.student_material__student_term__term__name;

          if (!results[year]) {
            results[year] = {} as (typeof results)[string];
          }
          if (!results[year][term]) {
            results[year][term] = [{ cgpa: material_degree.student_material__student_term__cgpa }];
            if ([AlertStatus.firstAlert, AlertStatus.secondAlert, AlertStatus.thirdAlert].includes(material_degree.student_material__student_term__status_alert)) {
              status_alert.push(`لديك انذار اكاديمي في العام الدراسي ${year} في الفصل الدراسي ${termName(term as '1' | '2' | '3')} ويرجي التواصل مع المرشد لحل المشكلة`);
            }
          }
          results[year][term].push({
            student_material__open_material__material_skill__material__name: material_degree.student_material__open_material__material_skill__material__name,
            evaluation: material_degree.evaluation,
            notes: material_degree.notes,
          });
        });
        setResult(results);
        setLoading(false);
        setAlert(status_alert);
        setSurveyData(null);
      } else {
        setSurveyData({ questions_survey: data.questions_survey, materials: data.materials });
        setLoading(false);
        setResult({});
      }
    };
    const requestOptions = {
      params: {
        main_target: 'results',
      },
    };
    if (loading) RequestGET(url_studentGate, requestOptions, handleDownload, handleError);
  }, [loading]);

  const closeAlert = React.useCallback(() => setAlert([]), []);
  return (
    <div>
      <div className="titles">
        <TitleTab name={Tabs.result.name} />
      </div>

      {Object.keys(resultsData).length > 0 && <ShowResult resultsData={resultsData} />}
      {surveyData && (
        <ShowSurvey
          surveyData={surveyData}
          setDoneSurvey={() => {
            setLoading(true);
            setSurveyData(null);
          }}
        />
      )}
      {alert.length > 0 && (
        <Alert
          title={'الملاحظات'}
          closeAlert={closeAlert}
          notes={alert}
          btns={
            <BtnPrimary
              verbose_name="الغاء"
              OnClickBtn={() => {
                setAlert([]);
              }}
            />
          }
        />
      )}

      {loading && <Loading />}
    </div>
  );
};

export default Result;
