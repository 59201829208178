import { SurveyDataType } from './ShowSurvey/ShowSurveyType';

export enum AlertStatus {
  firstTerm = '1',
  firstAlert = '2',
  secondAlert = '3',
  thirdAlert = '4',
  SUMMER = '5',
  noAlert = '6',
}
export enum EvaluationType {
  'A+' = 'ممتاز+',
  'A' = 'ممتاز',
  'A-' = 'ممتاز-',
  'B+' = 'جيد جدا+',
  'B' = 'جيد جدا',
  'B-' = 'جيد جدا-',
  'C+' = 'جيد+',
  'C' = 'جيد',
  'C-' = 'جيد-',
  'D+' = 'مقبول+',
  'D' = 'مقبول',
  'D-' = 'مقبول-',
  'F' = 'راسب',
  'FX' = 'محروم',
  'LC' = 'غير مكتمل',
  'W' = 'منسحب',
  'AB' = 'غياب',
}
export interface ResponseResult {
  typeResponse: 'results';
  resultsData: {
    evaluation: keyof typeof EvaluationType;
    student_material__student_term__term__name: '1' | '2' | '3';
    student_material__student_term__cgpa: number;
    student_material__student_term__term__academic_year__name: string;
    student_material__open_material__material_skill__material__name: string;
    student_material__student_term__status_alert: AlertStatus;
    notes: string | null;
  }[];
}
export type ResponseSurvey = { typeResponse: 'survey' } & SurveyDataType;
export type ResponseType = ResponseResult | ResponseSurvey;
