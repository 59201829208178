import React from 'react';
import '../../../Common/Table.css';
import './ShowResult.css';
import { termName } from '../../../Common/utilities';

enum EvaluationType {
  'A+' = 'ممتاز+',
  'A' = 'ممتاز',
  'A-' = 'ممتاز-',
  'B+' = 'جيد جدا+',
  'B' = 'جيد جدا',
  'B-' = 'جيد جدا-',
  'C+' = 'جيد+',
  'C' = 'جيد',
  'C-' = 'جيد-',
  'D+' = 'مقبول+',
  'D' = 'مقبول',
  'D-' = 'مقبول-',
  'F' = 'راسب',
  'FX' = 'محروم',
  'LC' = 'غير مكتمل',
  'W' = 'منسحب',
  'AB' = 'غياب',
}

export interface resultsDataType {
  [academic_year: string]: {
    [term in '1' | '2' | '3']: (
      | { evaluation: keyof typeof EvaluationType; student_material__open_material__material_skill__material__name: string; notes: string | null }
      | { cgpa: number }
    )[];
  };
}
interface PropsType {
  resultsData: resultsDataType;
}

const ShowResult: React.FC<PropsType> = (props) => {
  return (
    <div>
      {Object.keys(props.resultsData).length > 0 &&
        Object.entries(props.resultsData).map(([academic_year, terms]) =>
          Object.entries(terms).map(([term, materials_degree]) => (
            <React.Fragment key={`${academic_year}-${term}`}>
              <div key={academic_year + term} className="header_table_result">
                <p>العام الدراسي {academic_year}</p>
                <p>الفصل الدراسي {termName(term as '1' | '2' | '3')}</p>
              </div>
              <table key={academic_year + term} className="table">
                <thead className="thead">
                  <tr className="tr">
                    <th className="th">اسم المادة </th>
                    <th className="th">التقيم</th>
                    <th className="th">الملاحظات</th>
                  </tr>
                </thead>
                <tbody className="tbody">
                  {materials_degree.map((material_degree) =>
                    'cgpa' in material_degree ? (
                      <tr key={'cgpa'} className="tr">
                        <td className="td">المعدل التراكمي</td>
                        <td className="td">{material_degree.cgpa}</td>
                        <td className="td"></td>
                      </tr>
                    ) : (
                      <tr key={material_degree.student_material__open_material__material_skill__material__name} className="tr">
                        <td className="td">{material_degree.student_material__open_material__material_skill__material__name}</td>
                        <td className="td">{EvaluationType[material_degree.evaluation]}</td>
                        <td className="td">{material_degree.notes}</td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </React.Fragment>
          )),
        )}
    </div>
  );
};

export default ShowResult;
