import React from 'react';
import './ShowQuestion.css';
import Popup from '../../../../../../components/Popup/Popup';
import { currentTabQuestionType, exam_chapterType, questionType } from '../../../ChapterType';
import { errorType, RequestPOST, set_error_message } from '../../../../../../Request';
import { AxiosError } from 'axios';
import { url_studentGate } from '../../../../../../public';
import LoadingAlert from '../../../../../../Common/LoadingAlert/LoadingAlert';
import { useParams } from 'react-router-dom';

type PropsType = {
  currentTabQuestion: currentTabQuestionType;
  questions: questionType[];
  onClick: (currentTabQuestion: currentTabQuestionType) => void;
  setExam_chapters: (exam_chapters: exam_chapterType[]) => void;
};

const ShowQuestion: React.FC<PropsType> = (props) => {
  const [answers, setAnswers] = React.useState<{ [pk: number]: 'A' | 'B' | 'C' | 'D' }>({} as { pk: number; answer: 'A' | 'B' | 'C' | 'D' });
  const [imageZoom, setImageZoom] = React.useState<boolean>(false);
  const params = useParams();
  const chapterPK = params.chapterPK ? +params.chapterPK : undefined;
  ////////////////////////////////////////////////////////////////////////
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<errorType>([]);

  const handleError = React.useCallback((error: AxiosError): void => {
    setAlert(set_error_message(error));
    setLoading(false);
  }, []);

  const { setExam_chapters } = props;
  const handleDownload = React.useCallback(
    (data: exam_chapterType[]): void => {
      setLoading(false);
      setExam_chapters(data);
    },
    [setExam_chapters],
  );

  const sendExam = React.useCallback((): void => {
    const bodyFormData = new FormData();
    bodyFormData.append('main_target', 'platformAnswers');
    bodyFormData.append('answers', JSON.stringify(answers));
    if (chapterPK) bodyFormData.append('chapterPK', `${chapterPK}`);

    RequestPOST(url_studentGate, bodyFormData, handleDownload, handleError);
    setLoading(true);
  }, [chapterPK, answers, handleDownload, handleError]);
  ////////////////////////////////////////////////////////////////////////

  const currentQuestion = React.useMemo(() => {
    setImageZoom(false);
    if (typeof props.currentTabQuestion === 'number') {
      return props.questions[props.currentTabQuestion];
    }
    return null;
  }, [props.currentTabQuestion, props.questions]);

  return (
    <div className="ShowQuestion">
      {currentQuestion && (
        <>
          <div className="question">
            {currentQuestion.head && <h3 className="questionHead">{currentQuestion.head}</h3>}
            {currentQuestion.head_image &&
              (imageZoom ? (
                <Popup>
                  <img
                    onDoubleClick={() => setImageZoom(!imageZoom)}
                    className="questionZoomImage"
                    src={'https://student.rayaedu.org' + currentQuestion.head_image}
                    alt="questionImage"
                  />
                </Popup>
              ) : (
                <img
                  onDoubleClick={() => setImageZoom(!imageZoom)}
                  className="questionImage"
                  src={'https://student.rayaedu.org' + currentQuestion.head_image}
                  alt="questionImage"
                />
              ))}
          </div>
          <div className="answers">
            <button className={`answer ${answers[currentQuestion.pk] === 'A' ? 'selected' : ''}`} onClick={() => setAnswers({ ...answers, [currentQuestion.pk]: 'A' })}>
              {currentQuestion.A}
            </button>
            <button className={`answer ${answers[currentQuestion.pk] === 'B' ? 'selected' : ''}`} onClick={() => setAnswers({ ...answers, [currentQuestion.pk]: 'B' })}>
              {currentQuestion.B}
            </button>
            {currentQuestion.C && (
              <button className={`answer ${answers[currentQuestion.pk] === 'C' ? 'selected' : ''}`} onClick={() => setAnswers({ ...answers, [currentQuestion.pk]: 'C' })}>
                {currentQuestion.C}
              </button>
            )}
            {currentQuestion.D && (
              <button className={`answer ${answers[currentQuestion.pk] === 'D' ? 'selected' : ''}`} onClick={() => setAnswers({ ...answers, [currentQuestion.pk]: 'D' })}>
                {currentQuestion.D}
              </button>
            )}
          </div>
        </>
      )}
      {props.currentTabQuestion === 'done' && (
        <>
          <div className="question">
            <h3 className="sendAnswers">
              هل انت متاكد من تسليم الامتحان و تم حل عدد من الاسئلة {Object.keys(answers).length} من اصل {props.questions.length}
            </h3>
          </div>
          <div className="answers"></div>
        </>
      )}
      <button
        className="previous"
        onClick={() => props.onClick(props.currentTabQuestion === 'done' ? props.questions.length - 1 : props.currentTabQuestion !== 0 ? props.currentTabQuestion - 1 : 0)}
      >
        السابق
      </button>
      <button
        className="next"
        onClick={() => {
          if (props.currentTabQuestion === 'done') {
            sendExam();
            return;
          }
          props.onClick(props.currentTabQuestion === props.questions.length - 1 ? 'done' : props.currentTabQuestion + 1);
        }}
      >
        {props.currentTabQuestion === 'done' ? 'تسليم' : 'التالي'}
      </button>
      <LoadingAlert loading={loading} alert={alert} setAlert={setAlert} />
    </div>
  );
};

export default ShowQuestion;
