import React from 'react';
import './Login.css';
import { url_login_user } from '../public';
import logo from '../components/img/logo_raya.png';
import login_student from '../components/img/login_student.jpg';
import watermark from '../components/img/watermark.jpg';
import { RequestPOST, errorType, set_error_message } from '../Request';
import { AxiosError } from 'axios';
import Loading from '../components/Loading/Loading';
import Alert from '../components/Alert/Alert';
import BtnPrimary from '../components/Buttons/BtnPrimary/BtnPrimary';
const Login: React.FC = () => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [alert, setAalert] = React.useState<errorType>([]);

    const SetError = (error: AxiosError): void => {
        setAalert(set_error_message(error));
        setLoading(false);
    };
    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    const Download = (data: { user_verbose_name: string }): void => {
        setLoading(false);
        window.location.href = window.location.origin;
    };
    React.useEffect(() => {
        if (loading) {
            const bodyFormData = new FormData();
            bodyFormData.append('username', username);
            bodyFormData.append('password', password);
            RequestPOST(url_login_user, bodyFormData, Download, SetError);
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    return (
        <div className="login">
            <div className="login-inputs">
                <img src={logo} alt="logo"></img>
                <h1>
                    مرحبا بعودتك <i className="	fa fa-smile-o"></i>
                </h1>
                <div className="notes">
                    <p>قم بتسجيل دخولك باستخدام كودك </p>
                    <p>ورقم بطاقتك الصحيحين</p>
                </div>
                <div className="input user">
                    <i className="fa fa-user"></i>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                        placeholder="كود الطالب"
                    />
                </div>
                <div className="input password">
                    <i className="fa fa-unlock-alt"></i>
                    <input
                        type="text"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        placeholder="رقم البطاقة"
                    />
                </div>
                <button
                    type="button"
                    onClick={() => {
                        setLoading(true);
                    }}
                    name="submit"
                >
                    دخول
                </button>
                <div className="background-inputes-login">
                    <img src={watermark} alt="logo"></img>
                </div>
            </div>
            <div className="login-images">
                <img className="login-image" src={login_student} alt="login_Image"></img>
                <img className="blur-login" src={login_student} alt="login_Image"></img>
            </div>

            {loading ? <Loading /> : null}
            {alert.length ? (
                <Alert
                    title={'الملاحظات'}
                    notes={alert}
                    closeAlert={() => {
                        setAalert([]);
                    }}
                    btns={
                        <BtnPrimary
                            verbose_name="الغاء"
                            OnClickBtn={() => {
                                setAalert([]);
                            }}
                        />
                    }
                />
            ) : null}
        </div>
    );
};

export default Login;
